

class IntegrationBase {

    constructor(options, params, site) {
        this.options = options
        this.params = params
        this.site = site
    }

}

export default IntegrationBase
