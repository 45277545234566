import RenderBase from "./render-base.js";
import Api from "./api.js";

class RenderAddressForm extends RenderBase {

    #autocomplete

    constructor(options, site, member) {
        super(options, site, {}, member);
    }

    #setDefaultValues(contactForm, form) {
        const emailInput = form.querySelector('input[data-contact-email]');
        const firstNameInput = form.querySelector('input[data-contact-first-name]');
        const lastNameInput = form.querySelector('input[data-contact-last-name]');
        const idInput = form.querySelector('input[data-contact-id]');

        idInput.value = this.member ? this.member.ghost_guid : this.options.memberId

        if (emailInput) {
            emailInput.value = this.member ? this.member.email : this.options.memberEmail

            if (emailInput.value) {
                emailInput.readOnly = true

                // show contact form
                contactForm.classList.add('logged');
            }
        }
        if (firstNameInput) {
            const memberName = this.member ? this.member.name : '';
            let nameParts = memberName.split(' ');

            if (nameParts.length > 1) {
                firstNameInput.value = nameParts[0];

                nameParts.shift()
                lastNameInput.value = nameParts.join(' ');
            } else {
                firstNameInput.value = memberName;
            }
        }
    }

    #initAutocomplete(form) {
        if (typeof google !== 'undefined') {
            const address1Input = form.querySelector('input[data-contact-address1]');
            this.#autocomplete = new google.maps.places.Autocomplete(address1Input, {
                componentRestrictions: { country: ["de"] },
                fields: ["address_components", "geometry"],
                types: ["address"],
            });

            this.#autocomplete.addListener("place_changed", () => {
                const place = this.#autocomplete.getPlace();

                const result = {
                    line1: ["", "", ""],
                    city: "",
                    country: "",
                    postcode: "",
                };

                place.address_components.forEach((component) => {
                    component.types.forEach((type) => {
                        if (type === 'subpremise') {
                            result.line1[0] = component.long_name;
                        }
                        if (type === 'street_number') {
                            result.line1[1] = component.long_name;
                        }
                        if (type === 'route') {
                            result.line1[2] = component.long_name;
                        }
                        if (['locality'].includes(type)) {
                            result.city = component.long_name;
                        }
                        if (type === 'country') {
                            result.country = component.long_name;
                        }
                        if (['postal_code', 'postal_code_prefix'].includes(type)) {
                            result.postcode = component.long_name;
                        }
                    });
                });

                form.querySelector('input[data-contact-city]').value = result.city;
                form.querySelector('input[data-contact-zip]').value = result.postcode;
                form.querySelector('input[data-contact-address1]').value = result.line1.join(' ').trim();
            });
        }
    }

    render() {
        const options = this.options;
        const isPaidMember = options.memberId && options.memberPaid === 'true'

        if (!isPaidMember) {
            return
        }

        // attach form submit event
        Array.from(document.querySelectorAll('.outpost-address')).forEach(addressForm => {
            const form = addressForm.querySelector('form[data-outpost-address-form]');
            const errorEl = form.querySelector('.outpost-message-error');
            const site = this.site;

            // set member details, if available
            this.#setDefaultValues(addressForm, form)
            this.#initAutocomplete(form)

            const submitFormHandler = event => this.outpostAddressFormSubmitHandler({ event, errorEl, form, site, submitFormHandler });
            form.addEventListener('submit', submitFormHandler);
        });
    }

    outpostAddressFormSubmitHandler({ event, form, errorEl, site, submitHandler }) {
        const emailInput = event.target.querySelector('input[data-contact-email]');
        const firstNameInput = event.target.querySelector('input[data-contact-first-name]');
        const lastNameInput = event.target.querySelector('input[data-contact-last-name]');
        const countrySelect = event.target.querySelector('select[data-contact-country]');
        const cityInput = event.target.querySelector('input[data-contact-city]');
        const zipInput = event.target.querySelector('input[data-contact-zip]');
        const address1Input = event.target.querySelector('input[data-contact-address1]');
        const address2Input = event.target.querySelector('input[data-contact-address2]');
        const companyInput = event.target.querySelector('input[data-contact-company]');
        const guidInput = event.target.querySelector('input[data-contact-id]');

        let nameParts = [];
        if (firstNameInput?.value) {
            nameParts.push(firstNameInput.value);
        }
        if (lastNameInput?.value) {
            nameParts.push(lastNameInput.value);
        }

        const email = emailInput?.value;
        const name = nameParts.join(' ');
        const country = countrySelect?.value;
        const city = cityInput?.value;
        const zip = zipInput?.value;
        const address1 = address1Input?.value;
        const address2 = address2Input?.value;
        const company = companyInput?.value;
        const guid = guidInput?.value;

        form.removeEventListener('submit', submitHandler);
        event.preventDefault();

        this.resetFormClasses(form, errorEl);
        form.classList.add('loading');

        this.submitAddressForm({ email, name, address1, address2, company, zip, city, country, guid, form, errorEl, site, submitHandler });
    }

    submitAddressForm({ email, name, address1, address2, company, zip, city, country, guid, form, errorEl, site, submitHandler }) {
        const params = { email, name, address1, address2, company, zip, city, country, guid };

        const api = new Api(this.options)
        api.request(
            '/address-form',
            params,
            (response) => {
                form.addEventListener('submit', submitHandler);
                form.classList.remove('loading');

                if (response.success) {
                    form.classList.add('success');
                }
                window.scrollTo(0, 0);
            }, () => {
                if (errorEl) {
                    errorEl.innerText = 'There was an error in form submitting, please try again';
                }
                form.classList.remove('loading');
                form.classList.add('error');
                window.scrollTo(0, 0);
            }
        );
    }

}

export default RenderAddressForm
